.module-text--image {
    font-size: $fs-title-image;
    font-family: $ff2;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 2.0rem;
    @media (max-width: $bp-sm) {
        margin-bottom: 1.8rem;
    }

    
}