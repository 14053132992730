$black: #000;
$white: #FFF;
$gray-dark: #c0c2c3;
$gray-transparent-light: rgba($color: $gray-dark, $alpha: 0.05);
$gray-transparent-strong: rgba($color: $gray-dark, $alpha: 0.97);
$gray-light: rgba($color: $gray-dark, $alpha: 0.1);
$gray-bg-news: rgba($color: $gray-dark, $alpha: 0.35);
$gray-dw: rgba($color: $gray-dark, $alpha: 0.25);
// $blue-dark: rgba(0, 117, 190, 1);
// $blue-transparent-light: rgba(0, 117, 190, .05);
// $blue-transparent-strong: rgba(0, 117, 190, 0.97);
// $blue-light: rgb(235,248,253);
// $blue-bg-news:#F1F6FA;
// $blue-dw:#EBF8FD;

$font-base: 16px;
$column-gap: 2rem;
$site-gap: 2rem;

$bp-lg: 1200px;
$bp-md: 992px;
$bp-sm: 768px;
$bp-xsm: 468px;
$site-width: 55rem;

$ff2: "GreycliffCF-Regular", sans-serif;
$fs-huge: 3.75rem;
$fs-newstitle:1.125rem;
$fs-base:1rem;
$fs-title:2.625em;
$fs-title-image:1.500rem;