.module--download {
    border-bottom: 1px solid $gray-light;
    border-top: 1px solid $gray-light;

    .eqWrap {
        display: flex;
        .equalH {
            &.eq1 {
                width: 80%;
                span {
                    color: $gray-dark;
                    padding-top: 0.6rem;
                    padding-bottom: 0.6rem;
                    display: inline-block;
                }
                p{
                	padding-bottom: 0.6rem;
                	line-height: 1.5rem;
                }
            }
            &.eq2 {
                width: 80px;
                text-align: right;
                span {
                	text-align: right;
                    padding-top: 0.4rem;
                    padding-bottom: 0.4rem;
                    display: inline-block;
                }
            }
            &.eq3 {
                width: 14px;
                background: $gray-dw;

                svg {
                    padding-top: 10px;
                }
            }
        }
    }
}