// FOOTER
$height: 16.25rem;
$pad-bottom: 1.75rem;
$f-size: 1rem;
$h3-f-size: 1.12rem;
$h3-f-size-mob: 1.5rem;
$address-m-t: 4.37rem;


.footer {
  position: relative;
  padding: $pad-bottom 0;
  background: $gray-dark;
  color: $white !important;
  font: $ff2;
  font-size: $f-size;
  font-weight: 100;
  min-height: $height;

  h3 {
    padding-top: 0.94rem;
    font-size: $h3-f-size;
  }
  a {
    color: $white;
    text-decoration: none;
    @media (max-width:$bp-sm){
            font-size: $f-size/0.9;
    }
  }
  p {
    line-height: 1.62rem;
    //padding-bottom: 30;
  }
  .adresse {
    height: $height / 2 - $f-size/2;
    position: relative;
    p {
      position: absolute;
      bottom: 0;
      left: 1rem;
    }
    @media (max-width:$bp-sm){
      height: 1.625rem;
      margin-bottom: 1rem;
    }
  }
  .relative {
    position: relative;
  }

  .info {
    margin-top: $address-m-t;
  }

  .footer--logo {
    position: relative;
    top: -1rem;
    width: 196px;
    height: 57px;

    @media (max-width:$bp-sm){
      top: 0;
      left: -0.7rem;
      width: 147px;
      height: 38px;
    }
  }
  .sponsor-wrapper {
    text-align: right;
    .footer-sponsor {
      width: 127px;
      height: 30px;
    }
  }

  .line-top {
    border-top: 1px solid $white;
  }
}

// Tablet
@media screen and (max-width: $bp-md) {
  $address-m-t: 4.37rem;
  $menu-box-m-t: 2.81rem;

  .footer {
    .logo-footer {
      width: 222px;
    }
    .info {
      margin-top: $address-m-t - $menu-box-m-t;
    }
    .menu-box {
      &:last-of-type {
          @media (max-width:$bp-sm){
            margin-top: 1rem;
            margin-bottom: 1rem;
      }
      }
    }
  }
}

// Mobile
@media screen and (max-width: $bp-sm - 1) {
  $menu-box-m-t: 2.81rem;

  .footer {
    h3 {
      font-size: $h3-f-size-mob;
    }
    .sponsor-wrapper {
      text-align: left;
    }
    .menu-box, .info, .info img {
      margin-top: $menu-box-m-t;
      @media (max-width:$bp-sm){
            margin-top: 1rem;
      }
    }
  }
  .col-xsm-12 {
    width: 100%;
    float: left;
  }
}