.slidesjs-container {
    padding-bottom: 30px;
 
    h2 {
        font-size: $fs-title;
        color: $gray-dark;
        padding-bottom: 1rem;
        font-weight: 400;
    }
}

.slider {
    display: none;
    span {
        position: absolute;
        left: 0;
        bottom: -22px;
    }
}

.slider .slidesjs-navigation {
    margin-top: 3px;
}

.slider .slidesjs-previous {
    margin-right: 5px;
    float: left;
}

.slider .slidesjs-next {
    margin-right: 5px;
    float: left;
}

.slidesjs-pagination {
    margin: 0;
    float: right;
    list-style: none;
    transform: translateX(50%);
    left: -50%;
    position: relative;
    padding: 0;
    

    @media (max-width:$bp-sm){
          top:0;
    }
}

.slidesjs-pagination li {
    float: left;
    margin: 0 1px;
}

.slidesjs-pagination li a {
    display: block;
    width: 13px;
    height: 0;
    padding-top: 13px;
    background-image: url("../images/pagination.png");
    background-position: 0 0;
    float: left;
    overflow: hidden;
}

.slidesjs-pagination li a.active,
.slidesjs-pagination li a:hover.active {
    background-position: 0 -13px
}

.slidesjs-pagination li a:hover {
    background-position: 0 -26px
}

.slider a:link,
.slider a:visited {
    color: $white;
}

.slider a:hover,
.slider a:active {
    color: $white;
}