.module--blue {
    background: $gray-transparent-light;
    color: $gray-dark;
    .module-text--title-hidden {
        display: none;
    }
    .module-text--title {
        display: block;
        line-height: 2.5rem;
    }
    .module-text--text {
        font-size: 30px;
    }
    .module-text--text-small {
        font-size: 20px !important;
    }
    .richtext p:last-of-type {

        padding-bottom: 0rem !important;
    }

    .col-sm-8 {
        padding-top: 0.10rem;
    }
}