.module.module--header-image{
	margin-top: 0;
	margin-bottom: 0;
	@media (max-width:$bp-sm){
	      margin-top: 0.5rem;
	}
}
.module--header-image{
	position: relative;
	overflow: hidden;
	&.js-fit{
		.header-image--text-container{
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
.header-image--text{
	position: relative;
	z-index: 5;
	max-width: 45vw;
	padding-top: 1em;
	padding-bottom: 1em;

	font-size: $fs-huge;
	line-height: 1.1666666667em;
	color: $gray-dark;

	.colora{
    	p{
    		color:$gray-dark;
    	}
    	
    }
    .colorb{
    	p{
    		color:white;
    	}
    }

	@media (max-width:$bp-sm){
	      display: none;
	}
}
.header-image--text-container{
	width: 100%;
}
.header-image--image{
	position: relative;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
}