@import 'normalize';
@import 'variables';
@import 'mixins';
@import 'grid';
@import 'slider';
@import 'snippets/header';
@import 'snippets/footer';
@import 'modules/download';
@import 'modules/header-image';
@import 'modules/text-blue';
@import 'modules/news';
@import 'modules/title';
@import 'modules/text-image';
@import 'modules/text';
@import 'modules/video';

*::-moz-selection { background: $gray-dark; }
*::selection { background: $gray-dark; }

.home-text {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2rem;
    line-height: 1.2em;
    font-family: $ff2;

    span {
        color: $gray-dark;
    }
}

.icon {
    position: absolute;
    top: 0;
    right: 0;

    a {
        svg {
            height: 30px;
            width: 30px;
            padding-right: 0.5rem;
            padding-top: 0.5rem;
            transition: all .2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    @media (max-width:$bp-sm) {
        display: none;
    }
}


.error {
    padding-top: 5rem;

    h2 {
        font-size: $fs-title;
        color: $gray-dark;
        padding-bottom: 1rem;
    }
}

.bodywrapper {
    height: 100%;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.box,
.l-tc {
    display: table-cell;
}

.header,
.l-tr {
    display: table-row;
    height: 1px;
}

.main,
.l-t {
    display: table;
    width: 100%;
}


.module {
    margin: 4.5rem 0;
    @media screen and (max-width: $bp-sm - 1) {
        margin: 2.5rem 0;
    }
    &.text-3-columns {
        margin: 0.5rem 0;
    }
    .col-sm-8{
        .module-text--text{
            padding-top: 0.45rem;
        }
    }
}

html,
body {
    font-size: $font-base;
    font-family: $ff2;
    height: 100%;
    font-weight: 200;
}

img {
    width: 100%;
    height: auto;
}

a {
    color: $gray-dark;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    margin: 0;
}

.ta-r {
    text-align: right;
}

.ta-l {
    text-align: left;
}

.m-ta-r {
    @media screen and (max-width: $bp-sm - 1) {
        text-align: right;
    }
}

.lazyload,
.lazyloading {}

.lazyloaded {
    background-color: transparent;
}

.site-width {
    max-width: $site-width + $site-gap;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 $site-gap/1.0;

    @media (max-width: $bp-sm) {
        padding: 0 $site-gap/2.0;
    }
}

.full-width {
    padding-bottom: 4.5rem;
    padding-top: 4.5rem;
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: $bp-sm - 1) {
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
    }
    .module-text--text {
        font-size: 30px;
        font-family: $ff2;
    }
    .module-text--title {
        font-size: 30px;
        padding-bottom: 1em;
    }
}




.text-3-columns {
    .row>div {
        margin-top: 2rem;
        margin-bottom: 2rem;
        &:after {
            content: "";
            position: absolute;
            right: $column-gap/2;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: $gray-dark;
            @media screen and (max-width: $bp-sm - 1) {
                right: $site-gap/2;
                top: auto;
                bottom: 0;
                width: auto;
                height: 2px;
                left: $site-gap/2;
            }
        }
        &:last-of-type {
            &:after {
                content: none;
            }
        }
    }
}

table {
    width: 100%;
    thead {
        background: $gray-light;
        th {
            text-align: left;
            padding: 10px;
        }
        tr {
            td {
                width: auto;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $gray-light;
            td {
                padding: 10px;
            }
        }
    }
}


.google--maps {
    #map {
        height: 440px;
        background: $gray-light;
    }
}


.site-box {

    .team-flex {
        display: flex;
        flex-wrap: wrap;
    }

    .member {
        padding-bottom: 0rem;
        min-height: 27rem;
    }

    .module-box {
        display: flex;
        align-items: center;
    }


    .col-sm-3 {
        width: 33.3%;
        height: auto;
    }


    span {
        text-align: left;
        font-size: $fs-newstitle;
        font-family: $ff2;
        padding: 18px 5px 10px 0px;
        letter-spacing: 0.05em;
        color: $gray-dark;
        display: block;
    }
}


.header--team {

    height: 400px;
    max-height: 400px;
    background: $gray-dark;
    overflow: hidden;
 
    span {
        text-align: left;
        font-size: 18px;
        font-family: $ff2;
        padding: 15px 15px 12px 0px;
        letter-spacing: 0.05em;
        color: $white;
    }

    p {
        text-align: left;
        font-size: 32px;
        font-family: $ff2;
        padding: 58px 15px 12px 0px;
        letter-spacing: 0.05em;
        color: $white;
    }


    img {
        padding-top: 3.6rem;
        width: 100%;
        height: auto;

        @media (max-width:$bp-sm){
              padding-top: 2rem;
              max-width: 300px;
        }
    }
}


 


@media screen and (min-width: $bp-sm - 1) {

    .header--nav-mobile {
        display: none;
    }
}


@media screen and (max-width: $bp-sm - 1) {

    .header--sticky {
        padding-top: 15px;
    }

    .module-box {
        .col-sm-3 {
            width: 100%;
            padding-bottom: 60px;
        }
    }
    a {
        text-decoration: none;
    }
}

.show-mobile-nav {
    position: fixed;
    left: 0;
    width: 100%;
    transition: height 0.3s ease;
    &~.main {
        display: none;
    }
}

.richtext {
    line-height: 1.5em;
    h1 {
        font-size: 1.4em;
        font-weight: bold;
        padding-top: 1rem;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-position: outside;
        padding-left: 1rem;
    }
    img {
        width: 100%;
    }
    figure {
        margin: 0;
        padding: 0;
    }
    p {
        margin: 0;
        padding: 0;
        padding-bottom: 30px;

        &:last-of-type {
            padding-bottom: 2rem;
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
        padding-bottom: 1rem;
        li {
            padding-left: 1.05em;
            position: relative;
            line-height: 1.8rem;
            &:before {
                position: absolute;
                left: 0;
                top: -0.45rem;
                content: ".";
                color: $gray-dark;
                font-weight: bold;
                font-size: 27px;
            }
        }
    }
    strong {
        font-weight: bold;
        color: $gray-dark;
    }
    em {
        font-style: italic;
    }
}

.mobile-hidden {
    @media screen and (max-width: $bp-sm - 1) {
        display: none!important;
    }
}

.desktop-hidden {
    @media screen and (min-width: $bp-sm) {
        display: none!important;
    }
}