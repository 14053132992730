.news--block {

    &.news-detail {


        h2 {
            font-size:$fs-title;
            color: $gray-dark;
            padding-top: 1rem;
            padding-bottom: 0.5rem;
            line-height: 3.2rem;
        }
        p {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-size: $fs-base;
             line-height: 1.5rem;
        }
        
    }



    ul {
        padding:0;
/*
         column-count: 3;
         column-gap: 30px;
        @media (max-width: $bp-sm) {
            column-count: 1;
            column-gap: 0px;
        }
*/
        li {
            background: $gray-bg-news;
            margin-bottom: 1rem;
            display: inline-block;
           
            float:left;
            width:30.5%;
            margin: 10px 20px 20px 0px;

            @media (max-width: $bp-sm) {
                width: 97%;
            }
            span {
                display: block;
                padding: 0.8rem;
            
                p {
                    font-size: $fs-base;
                    line-height: 1.5rem;
                    font-weight: 100;
                }
            }
            a {
                display: inline-block;
                padding-top: 2.75rem;
                font-size: $fs-base;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
            h2 {
                font-size: $fs-newstitle;
                color: $gray-dark;
                padding-top: 0.5rem;
                padding-bottom: 1rem;
            }
            h3 {
                font-size: $fs-base*1.5;
                font-weight: bold;
                line-height: 2rem;
                color: $gray-dark;
                padding-top: 0rem;
                padding-bottom: 1rem;
            }
        }
    }
}