.header--sticky--table {
    display: table;
    width: 100%;
}

.header--sticky--cell {
    display: table-cell;
    float: none;
}

.header--sticky--cell-bottom {
    display: table-cell;
    float: none;
    vertical-align: bottom;
    @media screen and (max-width: $bp-sm - 1) {
        vertical-align: top;
        padding-right: 0;
    }
}

// NAV
$toggleBarHeight: 3px;

.header--sticky {
    padding-top: 3.5rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $white;
    z-index: 10;

    @media (max-width:$bp-sm){
        background-color: rgba(255,255,255,0.9);  
    }
}

.header--nav {
    font-family: $ff2;
    font-size: 1rem;

    @media (max-width:$bp-sm){
              margin-top: 1.5rem;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        float: right;

        .header--nav-toplevel {
            float: left;
            &:last-of-type {
                >a {
                    padding-right: 0;
                    &:after {
                        right: -0.33rem;
                    }
                }
            }
            >a {
                text-decoration: none;
                color: $black;
                display: block;
                padding-bottom: 1.8rem;
                padding-right: 1.7rem;
                padding-left: 0rem;
                position: relative;

                &:after {
                    content: ".";
                    position: absolute;
                    right: 1.33rem;
                    top: 0;
                    opacity: 0;
                    transform: translateY(0.33rem);
                    transition: opacity 0.5s ease, transform 0.5s ease;
                }
                &:hover,
                &.active {
                    color: $gray-dark;
                    &:after {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
        .header--nav-sublevel {
            text-align: left;
            margin-top: 1rem;
            border-top: .5px solid $white;
            display: block;
            float: none;
            a {
                display: block;
                width: 100%;
                padding-top: 1rem;
                min-height: 4em;
                padding-left: 0;
                margin-left: 0;
                color: $white;
                text-decoration: none;
                transition: transform 0.66s ease;
                letter-spacing: 0.04rem;
                &.active {
                    transform: translateX($site-gap/4);
                }
            }
            &:hover {
                a {
                    transform: translateX($site-gap/4);
                }
            }
        }
    }
    .header--nav-list-dropdown {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        display: none;
        a {
            line-height: 1.4rem;
        }
    }
    .header--nav-list-dropdown-inner {

        float: none;
        background-color: $gray-transparent-strong;
        padding-left: $site-gap/2;
        padding-right: $site-gap/2;
    }
}



.header--logo {
    width: 196px;
    height: 57px;
    position: relative;
    top: -0.8rem;

    @media (max-width:$bp-sm){
        top: 1rem;
        left: -0.7rem;
        width: 147px;
        height: 38px;
    }
}

.header--nav-toggler {
    position: relative;
    display: inline-block;
    height: 25px;
    width: 30px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    .nav-toggler--line {
        position: absolute;
        left: 0;
        width: 100%;
        height: $toggleBarHeight;
        background-color: $gray-dark;
    }
    .nav-toggler--line {

        transition: top 0.2s 0.2s ease, opacity 0s 0.1s linear, transform 0.1s 0s ease, margin 0s 0.2s linear;
        &:nth-of-type(1) {
            top: 0;
        }
        &:nth-of-type(2) {
            top: 50%;
            margin-top: -$toggleBarHeight/2;
        }
        &:nth-of-type(3) {
            top: 100%;
            margin-top: -$toggleBarHeight;
        }
    }
    &.is-open {
        .nav-toggler--line {
            transition: top 0.2s ease, opacity 0s 0.3s linear, transform 0.1s 0.4s ease;
            &:nth-of-type(1) {
                top: 50%;
                margin-top: -$toggleBarHeight/2;
                transform: rotateZ(-45deg);
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                margin-top: -$toggleBarHeight/2;
                transform: rotateZ(45deg);
            }
        }
    }
}

@media screen and (max-width: $bp-sm - 1) {
    .header--nav-mobile {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: $white;
        z-index: 100;
        overflow: hidden;
        height: 0px;
        overflow-y: scroll;
        padding: 0;
        box-sizing: border-box;
        >ul {
            padding: 0;
            list-style: none;
            >li {
                text-align: left;
                a {
                    text-decoration: none;
                    color: $black;
                    font-size: 23px;
                    font-family: $ff2;
                    padding: 1.3rem;
                    display: block;
                    position: relative;
                    letter-spacing: 0.05rem;
                    &.active {
                        color: $gray-dark;

                    }
                }
            }
        }
        .header--nav-mobile-toplevel {
            position: relative;
            display: block;
            box-sizing: border-box;
            a {
                padding-left: 1.2rem;
            }
            &.is-open {
                .header--nav-mobile-toplevel--arrow {
                    svg {

                        transform: rotateX(180deg) scale(0.8);
                        
                    }
                }
            }
        }
        .header--nav-mobile-toplevel--arrow {
            position: absolute;
            right: $site-gap;
            top: 1.5em;
            transform: translateY(-50%);
            svg {
                display: block;
                width: 1.8rem;
                height: 1rem;
                transition: transform 0.3s ease;
                transform: scale(0.8);
            }
        }
        .header--nav-mobile-sublevel {
            max-height: 0;
            overflow: hidden;
            li {
                background-color: $gray-dark;
                border-bottom: 2px solid $white;
                a {
                    padding-right: $site-gap/2;
                    color: $white;
                    &.active {
                        color: $white;
                        
                    }
                }
            }
        }
    }
}